<template>
  <div class="MyAddress">
    <div class="address-list">
      <div class="ivu-card">
        <div class="card-laft">{{$t('MyAddress.shdz')}}</div>
        <div class="card-right" @click="addAddress">{{$t('MyAddress.tjxdz')}}</div>
      </div>
      <div class="address">
        <div class="address-item" v-for="item in addressList" :key="item.id">
          <div class="address-header">
            <div class="name">{{item.name}}<span class="default" v-if="item.is_default">{{$t('MyAddress.mr')}}</span>
              <div class="address-action">
                <span @click="editAddress(item)">{{$t('MyAddress.xg')}}</span>
                <span @click.stop="delAddress(item)">{{$t('MyAddress.sc')}}</span>
              </div>
            </div>
            <div class="address-content">
              <p>{{$t('MyAddress.shr')}}: {{item.name}}</p>
              <p>{{$t('MyAddress.shdq')}}: {{item.consignee_address_path}}</p>
              <p>{{$t('MyAddress.xxdz')}}: {{item.detail}}</p>
              <p>{{$t('MyAddress.sjhm')}}: {{item.mobile}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <address-manage
      ref="address"
      :addressShow="addressShow"
      @addCancel = "addCancel"
      :delList = "delList"
      @transfer = "transfer"
      ></address-manage>
  </div>
</template>

<script>
import addressManage from "../../components/addressManage";
export default {
  name: 'MyAddress',
  components: { addressManage },
  data() {
    return {
      addressList: [],
      addressShow: false,
      delList: null,
    };
  },

  mounted() {
    this.getAddressList()
  },

  methods: {
    async getAddressList() {
      let res = await this.api.get(this.apiPath.addresslist,{});
      this.addressList = res.data
    },
    addAddress() {
      this.addressShow = true
    },
    addCancel(msg) {
      this.addressShow = msg
    },
    editAddress(item) {
      //修改地址
      this.delList = item
      setTimeout(()=> {
        this.addressShow = true
      })
    },
    transfer() {
      this.getAddressList()
    },
    async delAddress(item) {
      //删除地址
      let data = {
        id:item.id
      }
      let res = await this.api.post(this.apiPath.delAddress,data);
      if(res.code == 3){
        this.$message({
          message: $t('MyAddress.scdzcg'),
          type: 'success'
        });
        this.transfer()
      } else {
        this.$message({
          message: res.message,
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.MyAddress {
  width: 60%;
  padding: 30px 40px;
  .address-list {
    .ivu-card {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .card-laft {
        border-left: 3px solid #7F0114;
        padding-left: 10px;
        font-size: 15px;
      }
      .card-right {
        color: #7F0114;
        cursor: pointer;
      }
    }
    .address {
      .address-item {
        border-top: 1px solid #eee;
        padding: 30px;
        .address-header {
          .name {
            width: 100%;
            display: flex;
            font-size: 18px;
            position: relative;
            .address-action {
              position: absolute;
              right: 10px;
              span {
                cursor: pointer;
                margin-right: 10px;
                font-size: 14px;
                color: #7F0114;
              }
            }
          }
          .name .default{
            background: #ff9900;
            color: #fff;
            padding: 0 5px;
            border-radius: 4px;
            margin-left: 5px;
            font-size: 14px;
          }
          .address-content {
            cursor: pointer;
            p {
              padding: 12px 0;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .MyAddress {
    width: 100%;
  }
  .address-item {
    padding: 20px 0 !important;
  }
  
}
</style>
<style>
@media screen and (max-width: 750px) {
  .el-dialog {
    width: 100% !important;
  }
  .el-dialog__body {
    padding: 30px 0;
  }
}
</style>